$.fn.hasAttr = function(name) { return this.attr(name) !== undefined; };
$.ajaxSetup({ headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') } });
$('[data-toggle="tooltip"]').tooltip();
function isset() {
    var a = arguments, l = a.length, i = 0, undef;
    if(l === 0) { throw new Error('Empty isset'); }
    while(i !== l) {
        if(a[i] === undef || a[i] === null || a[i].length === 0) { return false; }
        i++;
    }
    return true;
}

//function for delaying operations
var delay = (function(){
  var timer = 0;
  return function(callback, ms){
    clearTimeout (timer);
    timer = setTimeout(callback, ms);
  };
})();

//formatting numbers
Number.prototype.format = function(n, x) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$& ');
};

//replacing array of chars
String.prototype.replaceArray = function(find, replace) {
	var replaceString = this;
	for (var i = 0; i < find.length; i++) {
		replaceString = replaceString.replace(find[i], replace[i]);
	}
	return replaceString;
};

var DMS = (function() {
	var my = {};
	my.dialog_close_refresh = false;
	my.calculateNet = function(v, print) {
		print = typeof print !== 'undefined' ? print : false;
		var out = Number(v);
		out = out / 1.23;
		if(print) {
			out = out.format(2);
			out = out.replace('.', ',');
			return out;
		} else {
			return out;
		}
	};
	my.calculateGross = function(v, print) {
		print = typeof print !== 'undefined' ? print : false;
		var out = Number(v);
		out = out * 1.23;
		if(print) {
			out = out.format(2);
			out = out.replace('.', ',');
			return out;
		} else {
			return out;
		}
	};
	my.print_price = function(v) {
		var out = parseFloat(v);
		if(isNaN(out)) out = 0;
		out = out.format(2);
		out = out.replace('.', ',');
		return out;
	};
	my.print_price2 = function(v) {
		var out = parseFloat(v);
		var n = 2;
		var x = undefined;
		var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
		out = out.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$& ');
		out = out.replace('.', ',');
		return out;
	};
	my.string_to_number = function(s) {
		if (s === null) s = '';
		s = s.replace(' ', '');
		s = s.replace(',', '.');
		var v = parseFloat(s);
		if(isNaN(v)) v = 0;
		return v;
	};
	my.print_time = function(v) {
		var s = 5;
		var minutes = v*s;
		var hours = Math.floor(minutes/60);
		var minutes_rest = minutes % 60;
		return hours+':'+String("00"+minutes_rest).slice(-2);
	}
	my.rm_pl_chars = function(s) {
		s = s.replaceArray(['ą','ć','ę','ł','ó','ś','ź','ż'], ['a','c','e','l','o','s','z','z']);
		s = s.replaceArray(['Ą','Ć','Ę','Ł','Ó','Ś','Ź','Ż'], ['A','C','E','L','O','S','Z','Z']);
		return s;
	}
	my.socket = function(__opts) {
		var opts = {
			module_id: __opts.module_id,
			app_id: __opts.app_id,
			token: __opts.token,
			tries: __opts.tries,
			broadcast_handler: __opts.broadcast_handler,
			debug: __opts.debug
		};
		var socket = io('https://websocketserver.ebusters.com.pl:180', { query: "token="+opts.token+"&app_id="+opts.app_id });
		socket.on('connect', function (data) {
			if(opts.debug) console.log('socket connect');
            $('.power-socket').addClass('power-socket-on');
            $('#power-socket-label').removeClass('label-danger').addClass('label-success');
            setInterval(function(){
                $.ajax({
                    method: "GET",
                    url: '/socket/update-token/'+opts.module_id+'/'+opts.token
                });
            },1000*60*10);
		});
		socket.on('broadcast.'+opts.app_id, opts.broadcast_handler);
		socket.on('error', function (data) {
			if(opts.debug) console.log('socket error');
            $('.power-socket').removeClass('power-socket-on');
            $('#power-socket-label').removeClass('label-success').addClass('label-danger');
		});
		socket.on('disconnect', function (data) {
			if(opts.debug) console.log('socket disconnect');
            $('.power-socket').removeClass('power-socket-on');
            $('#power-socket-label').removeClass('label-success').addClass('label-danger');
			opts.tries--;
			if(opts.tries > 0) {
				$.get('/socket/connect/'+opts.module_id, {'reconnect': 1}, function (response) {
					if(response.token !== 0) {
                        opts.token = response.token;
						my.socket(opts);
					} else {
						if(opts.debug) console.log('reconnect failed');
						console.log(response);
					}
				});
			}
		});
		socket.on('close', function (data) {
			if(opts.debug) console.log('socket close');
		});
		return socket;
	}
	my.send_dialog_form = function(btn) {
	    var error_msg = "Wystąpił błąd w trakcie wykonywania polecenia!";
		if($('#dialog-form').valid()) {
			if(btn.hasAttr('data-loading-text') || (!btn.hasAttr('off-disable') && !btn.hasClass('off-disable'))) {
				btn.button('loading');
			}
			if(btn.data('status')) {
				$('#dialog-form input[name="modal_status"]').val(btn.data('status'));
			}
			$.ajax({
				type: "POST",
				url: $('#dialog-form').prop('action'),
				data: $('#dialog-form').serialize(),
				cache: false,
				dataType: 'json',
				success: function (data) {
					if (data.code == '0') {
						window.location.reload();
					} else if (data.code == '1') {
						window.location = data.url;
					} else {
						$('#modal .modal-body').html(data.error);
						if (isset(data.url) && data.url != '') {
							btn.button('reset');
							$('#modal').on('hidden.bs.modal', function (e) {
								window.location = data.url;
							});
						}
					}
				},
				error: function(XMLHttpRequest, textStatus, errorThrown) {
					$('#modal .modal-body').html('<p class="text-danger">'+error_msg+'</p>');
				}
			});
		} else {
			btn.button('reset');
		}
		return 0;
	}
	return my;
}());

$.validator.setDefaults({
    errorPlacement: function(error, element) {
        if(element.parent('.input-group').length) {
            error.insertAfter(element.parent());
        } else {
            error.insertAfter(element);
        }
    }
});
$("form").validate();

/*
 * Translated default messages for the jQuery validation plugin.
 * Language: PL
 */
jQuery.extend(jQuery.validator.messages, {
    required: TRANS.validator.required,
    remote: TRANS.validator.remote,
    email: TRANS.validator.email,
    url: TRANS.validator.url,
    date: TRANS.validator.date,
    dateISO: TRANS.validator.dateISO,
    number: TRANS.validator.number,
    digits: TRANS.validator.digits,
    creditcard: TRANS.validator.creditcard,
    equalTo: TRANS.validator.equalTo,
    accept: TRANS.validator.accept,
    maxlength: jQuery.validator.format(TRANS.validator.maxlength),
    minlength: jQuery.validator.format(TRANS.validator.minlength),
    rangelength: jQuery.validator.format(TRANS.validator.rangelength),
    range: jQuery.validator.format(TRANS.validator.range),
    max: jQuery.validator.format(TRANS.validator.max),
    min: jQuery.validator.format(TRANS.validator.min)
});

jQuery.validator.addMethod("validDate", function(value, element) {
    return this.optional(element) || moment(value,"YYYY-MM-DD").isValid();
}, "Proszę o podanie prawidłowej daty YYYY-MM-DD");

/* Polish initialisation for the jQuery UI date picker plugin. */
/* Written by Jacek Wysocki (jacek.wysocki@gmail.com). */
jQuery(function($) {
	console.log(TRANS.lang);
    $.datepicker.regional['pl'] = {
        closeText: 'Zamknij',
        prevText: '&#x3c;Poprzedni',
        nextText: 'Następny&#x3e;',
        currentText: 'Dziś',
        monthNames: ['Styczeń','Luty','Marzec','Kwiecień','Maj','Czerwiec',
            'Lipiec','Sierpień','Wrzesień','Październik','Listopad','Grudzień'],
        monthNamesShort: ['Sty','Lu','Mar','Kw','Maj','Cze',
            'Lip','Sie','Wrz','Pa','Lis','Gru'],
        dayNames: ['Niedziela','Poniedziałek','Wtorek','Środa','Czwartek','Piątek','Sobota'],
        dayNamesShort: ['Nie','Pn','Wt','Śr','Czw','Pt','So'],
        dayNamesMin: ['N','Pn','Wt','Śr','Cz','Pt','So'],
        weekHeader: 'Tydz',
        dateFormat: 'yy-mm-dd',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ''};
    $.datepicker.setDefaults($.datepicker.regional['pl']);
});

//loader
//spinner
var spinner_opts = {
	  lines: 11 // The number of lines to draw
	, length: 14 // The length of each line
	, width: 14 // The line thickness
	, radius: 41 // The radius of the inner circle
	, scale: 1 // Scales overall size of the spinner
	, corners: 0.6 // Corner roundness (0..1)
	, color: '#000' // #rgb or #rrggbb or array of colors
	, opacity: 0.35 // Opacity of the lines
	, rotate: 0 // The rotation offset
	, direction: 1 // 1: clockwise, -1: counterclockwise
	, speed: 1 // Rounds per second
	, trail: 56 // Afterglow percentage
	, fps: 20 // Frames per second when using setTimeout() as a fallback for CSS
	, zIndex: 2e9 // The z-index (defaults to 2000000000)
	, className: 'spinner' // The CSS class to assign to the spinner
	, top: '50%' // Top position relative to parent
	, left: '50%' // Left position relative to parent
	, shadow: true // Whether to render a shadow
	, hwaccel: false // Whether to use hardware acceleration
	, position: 'fixed' // Element positioning
};
var spinner_target = null;
var spinner = null;
$(document).ready(function() {
	spinner_target = document.getElementById('loading-spinner')
	spinner = new Spinner(spinner_opts).spin(spinner_target);
	spinner.stop();
	$(document).ajaxStart(function() {
		spinner.spin(spinner_target);
	});
	$(document).ajaxStop(function() {
		spinner.stop();
	});
});

//lightbox
$(document).delegate('*[data-toggle="lightbox"]', 'click', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});

//datetimepicker
jQuery(function($) {
	//console.log(TRANS.lang);
	//jQuery.datetimepicker.setLocale(TRANS.lang);
});
