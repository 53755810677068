$(document).ready(function(){
    $(document).on('click','.form-short2 i.glyphicon-pencil', function(){
        var edit = '';
        edit = $(this).prev().html();
        $(this).prev().html('');
        $(this).prev().html('<input type="text" value="'+edit+'" style="width: 80%;"/>');
        $(this).removeClass('glyphicon-pencil');
        $(this).addClass('glyphicon-ok');
    });

    $(document).on('click','.form-short2 i.glyphicon-ok', function(){
        var edit = '';
        edit = $(this).prev().children('input').val();
        var id = $(this).parent('div.form-short2').attr('data-id');
        var name = $(this).parent('div.form-short2').attr('data-name');

        $.ajax({
            method: "POST",
            url: "/lead-management/input/short-edit/" + id,
            data: name+'='+edit
        }).done(function (data) {
            $('.alert-info-form').show();
            $('.alert-info-form').animate({ 'opacity':0}, 1000, function(){
                $(this).hide().css({'opacity':1});
            });
        }).error(function(data) {
            if(data.status == 401){
                alert(app.javascript.sesja);
                location.reload();
            }
        });

        $(this).prev().html(edit);
        $(this).removeClass('glyphicon-ok');
        $(this).addClass('glyphicon-pencil');
    });

    $(document).on('click','.form-short-marka2 i.glyphicon-pencil', function(){
        var edit = '';
        edit = $(this).prev().html();
        $(this).prev().html('');
        $(this).prev().html('<input type="text" value="'+edit+'" style="width: 80%;"  id="autocomplete_marka_car"/>');
        $(this).removeClass('glyphicon-pencil');
        $(this).addClass('glyphicon-ok');
    });

    $(document).on('click','.form-short-marka2 i.glyphicon-ok', function(){
        var edit = '';
        edit = $(this).prev().children('input').val();
        var id = $(this).parent('div.form-short-marka2').attr('data-id');
        var name = $(this).parent('div.form-short-marka2').attr('data-name');


        $.ajax({
            method: "POST",
            url: "/lead-management/input/short-edit-marka/" + id,
            data: name+'='+edit
        }).done(function (data) {
            $('.alert-info-form').show();
            $('.alert-info-form').animate({ 'opacity':0}, 1000, function(){
                $(this).hide().css({'opacity':1});
            });
        }).error(function(data) {
            if(data.status == 401){
                alert(app.javascript.sesja);
                location.reload();
            }
        });

        $(this).prev().html(edit);
        $(this).removeClass('glyphicon-ok');
        $(this).addClass('glyphicon-pencil');
    });

    $(document).on('click','.form-short-model2 i.glyphicon-pencil', function(){
        var edit = '';
        edit = $(this).prev().html();
        $(this).prev().html('');
        $(this).prev().html('<input type="text" value="'+edit+'" style="width: 80%;"  id="autocomplete_model_car"/>');
        $(this).removeClass('glyphicon-pencil');
        $(this).addClass('glyphicon-ok');
    });

    $(document).on('click','.form-short-model2 i.glyphicon-ok', function(){
        var edit = '';
        edit = $(this).prev().children('input').val();
        var id = $(this).parent('div.form-short-model2').attr('data-id');
        var name = $(this).parent('div.form-short-model2').attr('data-name');


        $.ajax({
            method: "POST",
            url: "/lead-management/input/short-edit-model/" + id,
            data: name+'='+edit
        }).done(function (data) {
            $('.alert-info-form').show();
            $('.alert-info-form').animate({ 'opacity':0}, 1000, function(){
                $(this).hide().css({'opacity':1});
            });
        }).error(function(data) {
            if(data.status == 401){
                alert(app.javascript.sesja);
                location.reload();
            }
        });

        $(this).prev().html(edit);
        $(this).removeClass('glyphicon-ok');
        $(this).addClass('glyphicon-pencil');
    });



    $(document).on('click','.form-short3 i.glyphicon-pencil', function(){
        var edit = '';
        edit = $(this).prev().html();
        $(this).prev().html('');
        $(this).prev().html('<input type="text" value="'+edit+'" style="width: 80%;"/>');
        $(this).removeClass('glyphicon-pencil');
        $(this).addClass('glyphicon-ok');
    });

    $(document).on('click','.form-short3 i.glyphicon-ok', function(){
        var edit = '';
        edit = $(this).prev().children('input').val();
        var id = $(this).parent('div.form-short3').attr('data-id');
        var name = $(this).parent('div.form-short3').attr('data-name');

        $.ajax({
            method: "POST",
            url: "/lead-management/input/short-edit/" + id,
            data: name+'='+edit
        }).done(function (data) {
            $('.alert-info-form').show();
            $('.alert-info-form').animate({ 'opacity':0}, 1000, function(){
                $(this).hide().css({'opacity':1});
            });
        }).error(function(data) {
            if(data.status == 401){
                alert(app.javascript.sesja);
                location.reload();
            }
        });

        $(this).prev().html(edit);
        $(this).removeClass('glyphicon-ok');
        $(this).addClass('glyphicon-pencil');
    });

    $(document).on('click','.form-short4 i.glyphicon-pencil', function(){
        var edit = '';
        console.log('test');
        edit = $(this).prev().html();
        $(this).prev().html('');
        $(this).prev().html('<textarea style="width: 80%;">'+edit+'</textarea>');
        $(this).removeClass('glyphicon-pencil');
        $(this).addClass('glyphicon-ok');
    });

    $(document).on('click','.form-short4 i.glyphicon-ok', function(){
        var edit = '';
        edit = $(this).prev().children('textarea').val();
        var id = $(this).parent('div.form-short4').attr('data-id');
        var name = $(this).parent('div.form-short4').attr('data-name');

        $.ajax({
            method: "POST",
            url: "/lead-management/input/short-edit/" + id,
            data: name+'='+edit
        }).done(function (data) {
            $('.alert-info-form').show();
            $('.alert-info-form').animate({ 'opacity':0}, 1000, function(){
                $(this).hide().css({'opacity':1});
            });
        }).error(function(data) {
            if(data.status == 401){
                alert(app.javascript.sesja);
                location.reload();
            }
        });

        $(this).prev().html(edit);
        $(this).removeClass('glyphicon-ok');
        $(this).addClass('glyphicon-pencil');
    });


    $(document).on('click','.form-short-car i.glyphicon-pencil', function(){
        var marka = $('.form-short-car-marka i.glyphicon-pencil');
        var model = $('.form-short-car-model i.glyphicon-pencil');

        var marka_edit = marka.prev().html();
        var model_edit = model.prev().html();
        url = '';
        if(marka_edit){
            url+=marka_edit+'/'+model_edit;
        }
        $.ajax({
            method: "GET",
            url: "/lead-management/input/brands/"+url
        }).done(function (data) {
            marka.prev().html('');
            marka.prev().html('<select id="car_change_marka">'+data['marka']+'</select>');
            marka.removeClass('glyphicon-pencil');
            marka.addClass('glyphicon-ok');

            model.prev().html('');
            model.prev().html('<select id="car_change_model">'+data['model']+'</select>');
            model.removeClass('glyphicon-pencil');
            model.addClass('glyphicon-ok');
        }).error(function(data) {
            if(data.status == 401){
                alert(app.javascript.sesja);
                location.reload();
            }
        });
    });

    $(document).on('change','#car_change_marka',function(){
        $.ajax({
            method: "GET",
            url: "/lead-management/input/models/"+$(this).val()
        }).done(function (data) {
            $('#car_change_model').html(data['model']);
        }).error(function(data) {
            if(data.status == 401){
                alert(app.javascript.sesja);
                location.reload();
            }
        });
    });

    $(document).on('click','.form-short-car i.glyphicon-ok', function(){
        var marka = $('.form-short-car-marka i.glyphicon-ok');
        var model = $('.form-short-car-model i.glyphicon-ok');

        var marka_edit = marka.prev().children('select').children('option:selected').text();;
        var model_edit = model.prev().children('select').children('option:selected').text();

        var marka_val = marka.prev().children('select').val();
        var model_val = model.prev().children('select').val();

        var id = $(this).parent('div.form-short-car').attr('data-id');

        $.ajax({
            method: "POST",
            url: "/lead-management/input/short-edit-car/" + id,
            data: 'marka_id='+marka_val+'&marka_text='+marka_edit+'&model_id='+model_val+'&model_text='+model_edit
        }).done(function (data) {
            $('.alert-info-form').show();
            $('.alert-info-form').animate({ 'opacity':0}, 1000, function(){
                $(this).hide().css({'opacity':1});
            });
        }).error(function(data) {
            if(data.status == 401){
                alert(app.javascript.sesja);
                location.reload();
            }
        });
        marka.prev().html(marka_edit);
        marka.removeClass('glyphicon-ok');
        marka.addClass('glyphicon-pencil');

        model.prev().html(model_edit);
        model.removeClass('glyphicon-ok');
        model.addClass('glyphicon-pencil');
    });
});