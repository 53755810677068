$(document).ready(function(){
    function check_use_parameters(){
        var parameters = [];
        $('#document #parameter div').each(function(){
            parameters.push($(this).attr('data-value'));
        });
        var code = $("#summernote").summernote("code");
        $('#document #parameter div').removeClass('green');
        for (var i = 0, len = parameters.length; i < len; ++i) {
            if (code.indexOf(parameters[i]) != -1) {
                $('[data-value="'+parameters[i]+'"]').addClass('green');
            }
        }
    }

    $('#summernote').summernote({
        height: 600,
        lang: 'pl-PL',
        toolbar: [
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['font', ['strikethrough', 'superscript', 'subscript']],
            ['fontsize', ['fontsize']],
            ['color', ['color']],
            ['insert', ['table','picture','link']],
            ['para', ['ul', 'ol', 'paragraph', 'height']],
            ['misc', ['fullscreen','codeview','undo','redo']],
            ['eventButton', ['event']]
        ],
        callbacks: {
            onImageUpload: function (files) {
                data = new FormData();
                data.append("file", files[0]);
                $.ajax({
                    url: "/documents/upload",
                    data: data,
                    cache: false,
                    contentType: false,
                    processData: false,
                    type: 'POST',
                    success: function(data){
                        var newImage = new Image();
                        newImage.src = '/documents/download/'+data['upload_id'];
                        $('#summernote').summernote('insertNode', newImage);
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        console.log(textStatus+" "+errorThrown);
                    }
                });
            }
        }
    });

    $('#document select[name=module_id]').change(function(){
        if($(this).val() != ''){
            $.ajax({
                method: "GET",
                url: '/documents/parameters/'+$(this).val(),
            }).done(function (data) {
                $('#document #parameter').html(data);
            });
        } else{
            $('#document #parameter').html('');
        }
    });
    if($('#document #parameter').length){
        check_use_parameters();
        $(function() {
            setInterval(function(){
                check_use_parameters();
            }, 10000);
        });
    }
});